import {BlockEmbed} from 'quill/blots/block';
export class PromosBlot extends BlockEmbed {
  static override blotName = 'promos';
  static override tagName = 'div';
  static override className = 'promo-slider';

  static override create() {
    const node = super.create() as HTMLElement;

    // Create a placeholder element
    const placeholder = document.createElement('div');
    placeholder.className = 'promo-slider-placeholder my-2';
    node.appendChild(placeholder);

    return node;
  }
}
