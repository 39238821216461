import {BlockEmbed} from 'quill/blots/block';

export class BlogBlot extends BlockEmbed {
  static override blotName = 'blog';
  static override tagName = 'div';
  static override className = 'blog';

  static override create() {
    const node = super.create() as HTMLElement;

    const placeholder = document.createElement('div');
    placeholder.className = 'blog-placeholder my-2';
    node.appendChild(placeholder);

    return node;
  }
}
